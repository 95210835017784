import React from 'react';
import './Css/Header.css'; // Updated path for the CSS file
import logo192 from '../assets/images/logo192.png';
import Head from '../assets/images/Header.jpg';
import { Button, Menu, MenuButton, MenuList, MenuItem, Box } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import Shipping from './Shipping';

const Header: React.FC = () => {
  return (
    <>
      <header className="header">
        <div className="header-content">
          <img src={logo192} alt="flymypackage" className="logo-image" />
          
          <nav className="header-nav">
            <ul className="nav-list">
              {/* Tracking Dropdown Menu */}
              <li className="nav-item">
                <Menu>
                  <MenuButton as={Button} rightIcon={<ChevronDownIcon />} className="login-button">
                    Tracking
                  </MenuButton>
                  <MenuList>
                    <MenuItem onClick={() => window.location.href = "#track-your-package"}>Track Your Package</MenuItem>
                    <MenuItem onClick={() => window.location.href = "#track-shipment-status"}>Shipment Status</MenuItem>
                  </MenuList>
                </Menu>
              </li>

              {/* Home Dropdown Menu */}
              <li className="nav-item">
                <Menu>
                  <MenuButton as={Button} rightIcon={<ChevronDownIcon />} className="login-button">
                    Home
                  </MenuButton>
                  <MenuList>
                    <MenuItem onClick={() => window.location.href = "#home-section1"}>Section 1</MenuItem>
                    <MenuItem onClick={() => window.location.href = "#home-section2"}>Section 2</MenuItem>
                    <MenuItem onClick={() => window.location.href = "#home-section3"}>Section 3</MenuItem>
                    <MenuItem onClick={() => window.location.href = "#home-section4"}>Section 4</MenuItem>
                  </MenuList>
                </Menu>
              </li>

              {/* Services Dropdown Menu */}
              <li className="nav-item">
                <Menu>
                  <MenuButton as={Button} rightIcon={<ChevronDownIcon />} className="login-button">
                    Services
                  </MenuButton>
                  <MenuList>
                    <MenuItem onClick={() => window.location.href = "#freight-services"}>Freight Services</MenuItem>
                    <MenuItem onClick={() => window.location.href = "#customs-clearance"}>Customs Clearance</MenuItem>
                    <MenuItem onClick={() => window.location.href = "#warehousing"}>Warehousing</MenuItem>
                    <MenuItem onClick={() => window.location.href = "#supply-chain"}>Supply Chain Solutions</MenuItem>
                  </MenuList>
                </Menu>
              </li>

              {/* Locations Dropdown Menu */}
              <li className="nav-item">
                <Menu>
                  <MenuButton as={Button} rightIcon={<ChevronDownIcon />} className="login-button">
                    Locations
                  </MenuButton>
                  <MenuList>
                    <MenuItem onClick={() => window.location.href = "#mississauga"}>Mississauga</MenuItem>
                    <MenuItem onClick={() => window.location.href = "#toronto"}>Calgary</MenuItem>
                    <MenuItem onClick={() => window.location.href = "#brampton"}>Dubai</MenuItem>
                  </MenuList>
                </Menu>
              </li>

              {/* Contact Us Dropdown Menu */}
              <li className="nav-item">
                <Menu>
                  <MenuButton as={Button} rightIcon={<ChevronDownIcon />} className="login-button">
                    Contact Us
                  </MenuButton>
                  <MenuList>
                    <MenuItem onClick={() => window.location.href = "#customer-support"}>Customer Support</MenuItem>
                    <MenuItem onClick={() => window.location.href = "#sales"}>Sales Inquiries</MenuItem>
                    <MenuItem onClick={() => window.location.href = "#locations-contact"}>Location Contacts</MenuItem>
                  </MenuList>
                </Menu>
              </li>
            </ul>
            <Shipping />

            <Box className="header-buttons" display="flex" alignItems="center">
              <Button
                variant="solid"
                className="lo-button"
                onClick={() => window.location.href = "http://lvs.flymypackage.com/lvs/clvs-manager"}
              >
                Login
              </Button>
            </Box>
          </nav>
        </div>
      </header>
      <div className="hero-section">
  <img src={Head} alt="flymypackage" className="hero-image" />
  <div className="overlay"></div>
  <div className="hero-text">
    <h1>
    Swift, Secure, and Economical<br />
    Delivering Your Package Safely Around the World,<br />
    <b>Fast.</b>
    </h1>
  </div>
</div>

    </>
  );
};

export default Header;
