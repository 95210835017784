import React, { useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';

const ShippingForm: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const [fromName, setFromName] = useState('');
  const [fromCompany, setFromCompany] = useState('');
  const [fromCountry, setFromCountry] = useState('Canada');
  const [fromAddress, setFromAddress] = useState('');
  const [fromPostalCode, setFromPostalCode] = useState('');
  const [fromCity, setFromCity] = useState('');
  const [fromProvince, setFromProvince] = useState('');
  const [fromPhone, setFromPhone] = useState('');
  const [fromEmail, setFromEmail] = useState('');

  const [toName, setToName] = useState('');
  const [toCompany, setToCompany] = useState('');
  const [toCountry, setToCountry] = useState('');
  const [toAddress, setToAddress] = useState('');
  const [toPostalCode, setToPostalCode] = useState('');
  const [toCity, setToCity] = useState('');
  const [toPhone, setToPhone] = useState('');
  const [toEmail, setToEmail] = useState('');
  const [isResidential, setIsResidential] = useState(false);

  const handleSubmit = () => {
    console.log({
      fromName,
      fromCompany,
      fromCountry,
      fromAddress,
      fromPostalCode,
      fromCity,
      fromProvince,
      fromPhone,
      fromEmail,
      toName,
      toCompany,
      toCountry,
      toAddress,
      toPostalCode,
      toCity,
      toPhone,
      toEmail,
      isResidential,
    });
    setIsOpen(false);
  };

  return (
    <Box>
      <Button onClick={() => setIsOpen(true)}>Ship Now</Button>

      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} size="xl"> {/* Changed from "full" to "md" */}
      <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center">Address Information</ModalHeader> {/* Centered Header */}
          <ModalBody>
            <Text textAlign="center" mb={4}>
              Enter your (From) address and the recipient's (To) address.
            </Text>

            <Flex>
              <Box flex="1" pr={2}>
                <Text fontWeight="bold" mb={2}>From Address</Text>
                <FormControl mb={4}>
                  <FormLabel>Your Name</FormLabel>
                  <Input size="sm" value={fromName} onChange={(e) => setFromName(e.target.value)} />
                </FormControl>
                <FormControl mb={4}>
                  <FormLabel>Company</FormLabel>
                  <Input size="sm" value={fromCompany} onChange={(e) => setFromCompany(e.target.value)} />
                </FormControl>
                <FormControl mb={4}>
                  <FormLabel>Country</FormLabel>
                  <Select size="sm" value={fromCountry} onChange={(e) => setFromCountry(e.target.value)}>
                    <option value="Canada">Canada</option>
                    <option value="USA">USA</option>
                  </Select>
                </FormControl>
                <FormControl mb={4}>
                  <FormLabel>Address</FormLabel>
                  <Input size="sm" value={fromAddress} onChange={(e) => setFromAddress(e.target.value)} />
                </FormControl>
                <FormControl mb={4}>
                  <FormLabel>Postal Code</FormLabel>
                  <Input size="sm" value={fromPostalCode} onChange={(e) => setFromPostalCode(e.target.value)} />
                </FormControl>
                <FormControl mb={4}>
                  <FormLabel>City</FormLabel>
                  <Input size="sm" value={fromCity} onChange={(e) => setFromCity(e.target.value)} />
                </FormControl>
                <FormControl mb={4}>
                  <FormLabel>Province</FormLabel>
                  <Input size="sm" value={fromProvince} onChange={(e) => setFromProvince(e.target.value)} />
                </FormControl>
                <FormControl mb={4}>
                  <FormLabel>Phone</FormLabel>
                  <Input size="sm" value={fromPhone} onChange={(e) => setFromPhone(e.target.value)} />
                </FormControl>
                <FormControl mb={4}>
                  <FormLabel>Email</FormLabel>
                  <Input size="sm" type="email" value={fromEmail} onChange={(e) => setFromEmail(e.target.value)} />
                </FormControl>
              </Box>

              <Box flex="1" pl={2}>
                <Text fontWeight="bold" mb={2}>To Address</Text>
                <FormControl mb={4}>
                  <FormLabel>Your Name</FormLabel>
                  <Input size="sm" value={toName} onChange={(e) => setToName(e.target.value)} />
                </FormControl>
                <FormControl mb={4}>
                  <FormLabel>Company</FormLabel>
                  <Input size="sm" value={toCompany} onChange={(e) => setToCompany(e.target.value)} />
                </FormControl>
                <FormControl mb={4}>
                  <FormLabel>Country</FormLabel>
                  <Select size="sm" value={toCountry} onChange={(e) => setToCountry(e.target.value)}>
                    <option value="Canada">Canada</option>
                    <option value="USA">USA</option>
                  </Select>
                </FormControl>
                <FormControl mb={4}>
                  <FormLabel>Address</FormLabel>
                  <Input size="sm" value={toAddress} onChange={(e) => setToAddress(e.target.value)} />
                </FormControl>
                <FormControl mb={4}>
                  <FormLabel>Postal Code</FormLabel>
                  <Input size="sm" value={toPostalCode} onChange={(e) => setToPostalCode(e.target.value)} />
                </FormControl>
                <FormControl mb={4}>
                  <FormLabel>City</FormLabel>
                  <Input size="sm" value={toCity} onChange={(e) => setToCity(e.target.value)} />
                </FormControl>
                <FormControl mb={4}>
                  <FormLabel>Phone</FormLabel>
                  <Input size="sm" value={toPhone} onChange={(e) => setToPhone(e.target.value)} />
                </FormControl>
                <FormControl mb={4}>
                  <FormLabel>Email</FormLabel>
                  <Input size="sm" type="email" value={toEmail} onChange={(e) => setToEmail(e.target.value)} />
                </FormControl>
              </Box>
            </Flex>

            <FormControl display="flex" alignItems="center" mt={4}>
              <Checkbox isChecked={isResidential} onChange={(e) => setIsResidential(e.target.checked)}>
                Is Residential?
              </Checkbox>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handleSubmit}>Continue</Button>
            <Button variant="ghost" onClick={() => setIsOpen(false)}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ShippingForm;
